<template>
  <div>
    <div class>
      <div class="cursor-pointer flex justify-end d-print-none mt-2">
        <el-tooltip class="item" effect="dark" content="In nội dung" placement="bottom">
          <div @click="handlePrint" class="mr-4 mt-1">
            <svg
              class="mr-0"
              width="28"
              height="28"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M6.5 2.5H17.5V7.5H21.5V18.5H17.5V21.5H6.5V18.5H2.5V7.5H6.5V2.5ZM6.5 17.5V13.5H17.5V17.5H20.5V8.5H3.5V17.5H6.5ZM16.5 3.5V7.5H7.5V3.5H16.5ZM16.5 20.5H7.5V14.5H16.5V20.5Z"
                fill="#20409B"
              />
            </svg>
          </div>
        </el-tooltip>

        <div @click="()=>{$router.go(-1)}">
          <svg class="mr-3" width="33" height="33" viewBox="0 0 33 33" fill="none">
            <rect
              x="23.2937"
              y="8.11432"
              width="2"
              height="22"
              rx="1"
              transform="rotate(45 23.2937 8.11432)"
              fill="#4F4F4F"
            />
            <rect
              x="24.7079"
              y="23.6707"
              width="2"
              height="22"
              rx="1"
              transform="rotate(135 24.7079 23.6707)"
              fill="#4F4F4F"
            />
          </svg>
        </div>
      </div>
      <div v-html="content"></div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'PrintOnlyContent',
  computed: {
    ...mapState({
      content: state => state.upToDate.content
    })
  },
  mounted () {
    if (this.$route.query.type === 'print') {
      window.print()
    }

    this.$nextTick(() => {
      if (this.$route.query.type === 'print') {
        this.$router.replace({ query: null })
      }
    })
  },
  methods: {
    handlePrint () {
      window.print()
    }
  }
}
</script>

<style lang="scss" scoped>
@import url("https://www.uptodate.com/app/utd-all-eea42eeac4c944e52b22-2.css");
@import url("https://www.uptodate.com/app/utd-all-eea42eeac4c944e52b22-3.css");
@import url("https://www.uptodate.com/app/utd-all-eea42eeac4c944e52b22-4.css");

.cs-button-icon {
  padding: 2px;
  border: 1px solid #9e9d9d;
  border-radius: 4px;
  cursor: pointer;
}
</style>